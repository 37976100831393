import i18n from "@/lang";
var columns = [{
  title: 'common.postID',
  // 国际化，在lang里面查找
  dataIndex: 'id',
  key: 'id',
  width: '13%',
  slots: {
    title: 'customTitle'
  },
  scopedSlots: {
    customRender: 'id'
  }
}, {
  title: 'common.createTime',
  dataIndex: 'createtime',
  key: 'createtime',
  width: '15%',
  sorter: true,
  scopedSlots: {
    customRender: 'createtime'
  }
}, {
  title: 'common.type',
  dataIndex: 'type',
  key: 'type',
  width: '10%',
  scopedSlots: {
    customRender: 'type'
  }
}, {
  title: 'common.postTitle',
  dataIndex: 'name',
  key: 'name',
  width: '25%',
  scopedSlots: {
    customRender: 'name'
  }
}, {
  title: 'dcReview.source',
  dataIndex: 'sourcetype',
  key: 'sourcetype',
  width: '15%',
  scopedSlots: {
    customRender: 'sourcetype'
  }
}, {
  title: 'dcReview.creator',
  dataIndex: 'username',
  key: 'username',
  width: '15%',
  scopedSlots: {
    customRender: 'username'
  }
}, {
  title: 'common.state',
  dataIndex: 'state',
  key: 'state',
  width: '12%',
  scopedSlots: {
    customRender: 'state'
  }
}, {
  title: 'common.operation',
  key: 'action',
  dataIndex: 'action',
  fixed: "right",
  width: '180px',
  scopedSlots: {
    customRender: 'action'
  }
}];
var logColumns = [{
  title: 'common.logID',
  dataIndex: 'id',
  key: 'id',
  width: '25%',
  scopedSlots: {
    customRender: 'id'
  }
}, {
  title: 'common.createTime',
  dataIndex: 'createtime',
  key: 'createtime',
  width: '20%',
  scopedSlots: {
    customRender: 'createtime'
  }
}, {
  title: 'common.remark',
  dataIndex: 'memo',
  key: 'memo',
  scopedSlots: {
    customRender: 'memo'
  }
}];
export { columns, logColumns };
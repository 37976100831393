import i18n from "@/lang";
var searchKeys = [{
  key: 'type',
  label: i18n.tc('dcReview.type'),
  placeholder: i18n.tc('dcReview.type'),
  required: false,
  rules: [],
  select: true,
  initialValue: '',
  module: ['dcReview']
}, {
  key: 'type',
  label: i18n.tc('dcReview.type'),
  placeholder: i18n.tc('dcReview.type'),
  required: false,
  rules: [],
  select: true,
  initialValue: 1,
  module: ['dcVideo']
}, {
  key: "videoids",
  label: i18n.tc('dcReview.postId'),
  placeholder: i18n.tc('dcReview.postId') + ', ' + i18n.tc('common.multipleRowsByEachID'),
  required: false,
  rules: [],
  textarea: true,
  trim: false,
  hidden: true
}, {
  key: "username",
  label: i18n.tc('dcReview.username'),
  placeholder: i18n.tc('dcReview.username'),
  required: false,
  rules: [],
  input: true,
  trim: true
}, {
  key: "title",
  label: i18n.tc('dcReview.keyword'),
  placeholder: i18n.tc('dcReview.keyword'),
  required: false,
  rules: [],
  input: true,
  trim: true
}, {
  key: 'status',
  label: i18n.tc('dcReview.state'),
  placeholder: i18n.tc('dcReview.state'),
  required: false,
  rules: [],
  select: true,
  module: ['dcReview'],
  initialValue: 0
}, {
  key: 'status',
  label: i18n.tc('dcReview.state'),
  placeholder: i18n.tc('dcReview.state'),
  required: false,
  rules: [],
  select: true,
  module: ['dcVideo'],
  initialValue: ''
}, {
  key: "uids",
  label: i18n.tc('dcReview.creatorID'),
  placeholder: i18n.tc('dcReview.creatorID') + ', ' + i18n.tc('common.multipleRowsByEachID'),
  required: false,
  rules: [],
  textarea: true,
  trim: false,
  hidden: true
}, {
  key: "date",
  label: i18n.tc('dcReview.createTime'),
  format: "YYYY-MM-DD HH:mm",
  placeholder: [i18n.tc('dcReview.beginTime'), i18n.tc('dcReview.overTime')],
  rules: [],
  dates: true
}, {
  key: "sourcetypes",
  label: i18n.tc('dcReview.source'),
  required: false,
  rules: [],
  select: true,
  mode: 'multiple',
  initialValue: undefined,
  placeholder: i18n.tc('dcReview.source')
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
export { searchKeys };